<svelte:options
  customElement={{
    tag: 'xlent-job-listing',
    shadow: 'none',
    props: {
      teamTailorId: { attribute: 'team-tailor-id' },
      apiKey: { attribute: 'api-key' },
      jobLimit: { attribute: 'job-limit', type: 'Number' },
      availableJobsText: { attribute: 'available-jobs-text' },
      city: { attribute: 'city' }
    }
  }}
/>

<script lang="ts">
  import { onMount } from 'svelte';

  export let teamTailorId: string;
  export let apiKey: string;
  export let jobLimit: number = 10; // Default to 10 jobs if not provided
  export let availableJobsText: string;
  export let city: string;

  let hasJobs = false;

  async function checkJobs(): Promise<void> {
    if(!teamTailorId || !apiKey) {
      console.error('Missing required properties: teamTailorId or apiKey');
      return;
    }

    const url = `https://api.teamtailor.com/v1/jobs?filter[locations]=${teamTailorId}&include=department,role,regions,locations`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'X-Api-Version': '20240404' // Find Api Version in documentation https://docs.teamtailor.com/
        }
      });

      if(!response.ok) {
        console.error(`Failed to fetch jobs: ${response.statusText}`);
        return;
      }

      const data = await response.json();

      hasJobs = Array.isArray(data.data) && data.data.length > 0;
    } catch(error) {
      console.error('Error fetching jobs:', error);
    }
  }

  onMount(checkJobs);
</script>

{#if hasJobs}
  <div class="job-listing-block">
    <div class="content-container">
      <h2>{availableJobsText} {city}</h2>
      <div class="job-listing">
        <script
          src="https://scripts.teamtailor-cdn.com/widgets/production/jobs.js"
          async
          charset="utf-8"
        >
        </script>
        <div
          class="teamtailor-jobs-widget"
          data-teamtailor-limit={jobLimit}
          data-teamtailor-popup="true"
          data-teamtailor-pagination="true"
          data-teamtailor-remote-status-select="true"
          data-teamtailor-location={teamTailorId}
          data-teamtailor-api-key={apiKey}
        ></div>
      </div>
    </div>
  </div>
{/if}
